// Display

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex';

@each $display in $displays {
  .is-#{$display} {
    display: #{$display}; }
  .is-#{$display}-mobile {
    @include mobile {
      display: #{$display} !important; } }
  .is-#{$display}-tablet {
    @include tablet {
      display: #{$display} !important; } }
  .is-#{$display}-tablet-only {
    @include tablet-only {
      display: #{$display} !important; } }
  .is-#{$display}-touch {
    @include touch {
      display: #{$display} !important; } }
  .is-#{$display}-desktop {
    @include desktop {
      display: #{$display} !important; } }
  .is-#{$display}-desktop-only {
    @include desktop-only {
      display: #{$display} !important; } }
  .is-#{$display}-widescreen {
    @include widescreen {
      display: #{$display} !important; } } }

// Float

.is-clearfix {
  @include clearfix; }

.is-pulled-left {
  float: left; }

.is-pulled-right {
  float: right; }

// Overflow

.is-clipped {
  overflow: hidden !important; }

// Overlay

.is-overlay {
  @include overlay; }

// Text

.has-text-centered {
  text-align: center; }

.has-text-left {
  text-align: left; }

.has-text-right {
  text-align: right; }

// Visibility

.is-hidden {
  display: none !important; }

.is-hidden-mobile {
  @include mobile {
    display: none !important; } }

.is-hidden-tablet {
  @include tablet {
    display: none !important; } }

.is-hidden-tablet-only {
  @include tablet-only {
    display: none !important; } }

.is-hidden-touch {
  @include touch {
    display: none !important; } }

.is-hidden-desktop {
  @include desktop {
    display: none !important; } }

.is-hidden-desktop-only {
  @include desktop-only {
    display: none !important; } }

.is-hidden-widescreen {
  @include widescreen {
    display: none !important; } }

// Other

.is-disabled {
  pointer-events: none; }

.is-marginless {
  margin: 0 !important; }

.is-paddingless {
  padding: 0 !important; }

.is-unselectable {
  @include unselectable; }
