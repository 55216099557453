.home--intro {
	position: relative;
	height: 100vh;
	// background-image: url('../images/home-background.png');
	background-position: right;
	background-size: auto 100%;
	background-repeat: no-repeat;
	.caption {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: 10;
		width: 90%;
		text-align: center;

		@include tablet() {
			width: 50%;
		}

		h4 {
			font-size: 2.6vw;
			@include tablet() {
				font-size: 1.2vw;
			}
		}

		h2 {
			font-size: 3.5vw;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 0.1em;
		}
		.arrow-down {
			display: inline-block;
			border-color: white;
			color: white;
			margin-top: 50px;
		}

		

		.content--title {
			margin-bottom: 30px;
			.content--title--inner {
				
				font-size: 8vw;
				line-height: 1.2em;
				text-transform: uppercase;
				font-weight: 900;
				position: relative;
				display: inline-block;
				letter-spacing: 0.1em;
				@include tablet() {
					font-size: 4vw;
				}
				&.offset {
					// margin-left: 50%;
					// transform: translateX(-50%);
				}
			}
			
		}
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		// background-image: linear-gradient(-126deg, rgba($primary,0.9) 0%, rgba($primarySpin,0.9) 100%);
		background-color: $gray;
	}
}