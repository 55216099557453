.services--text {
	margin-top: 20%;
}

.services--pod {
	padding: 0 20px;
	.services--icon {
		img {
			width: 100px;
			height: auto;
			display: block;
			margin: 0 auto;
		}
		
	}
	.text {
		margin: 40px 0;
		text-align: center;
	}
}