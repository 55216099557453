body{
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6em;
	@include mobile(){
		font-size: 14px;
	}
}
p {
	margin-bottom: 20px;
	letter-spacing: 1px;
}
h1,h2,h3,h4,h5{
	letter-spacing: 0.1em;
	line-height: 1.2em;
	margin-bottom: 20px;
}
.content {
	h1,h2,h3 {
		font-weight: 800;
	}
}
h1,h2,h3 {
	font-weight: bold;
}
h1 {
	font-size: 3.5em;
}
h2, .content h2{
	font-size: 3em;
	// font-weight: 300;
}

a{
	color: $primary;
	&:hover{
		text-decoration: none;
	}
}


a.mathildas-link {
	display: inline-block;
	position: relative;
	color: $text-color;
	margin-top: 50px;
	font-weight: 800;
		transition: all 0.3s linear;

	font-size: 48px;
	line-height: 1.4em;
	&:before {
		content: ' ';
		position: absolute;
		display: block;
		top: 0px;
		left: 0;
		height: 100%;
		background: $primary;
		transition: all 0.3s linear;
		width: 0;
	}
	&:hover {
		color: white;
		&:before {
			width: 100%;
		}
		
	}
}
