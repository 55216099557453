.page--container{
	.form-group {
	position: relative;
	margin-top: 50px;
	margin-bottom: 50px;
	// overflow-x: hidden;

	
	&:before {
		content: ' ';
	}

	&:before {
		content: ' ';
		pointer-events: none;
		position: absolute;
		left: 0%;
		background: $dark;
		width: 0%;
		top: 0;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		height: 100%;

	}
	&:after {
		content: ' ';
		pointer-events: none;
		position: absolute;
		right: 0%;
		background: darken($gray, 5%);
		width: 100%;
		top: 0;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		height: 100%;

	}

	.control-label {
		z-index: 20;
		top: 0;
		position: absolute;
	    left: 0;
	    padding: 20px;
	    transition: top 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	    width: 100%;
	    height: 100%;
	    text-align: left;
	    pointer-events: none;
	}
	.form-control {
		display: block;
		box-shadow: none;
		padding: 20px;
		width: 100%;
		color: rgba(255,255,255,0.8);
		position: relative;
		font-size: inherit;
		border: none;
		// background: darken($gray, 5%);
		background: transparent;
		border-bottom: 2px solid $primary;

		&:focus {
			border-color: $primary;
			box-shadow: none;
			outline: none;
		}

	}
	&.focused {
		&:before {
			width: 100%;
		}
		&:after {
			width: 0%;
		}
		.control-label {
			top: -50px;
		}

	}
	.text-muted {
		float: right;
		color: $danger;
		font-size: 0.6em;
		color: lighten($text-color, 20%);
	}

}

fieldset {
	border: none;
}
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  opacity: 0;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  opacity: 0;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  opacity: 0;
	}
	:-moz-placeholder { /* Firefox 18- */
	  opacity: 0;
	}
