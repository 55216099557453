.btn {
	border-radius: 50px;
	padding: 13px 30px;
	&.btn-default {
		background-color: transparent;
		// color: white;
	}
}

.button {
	position: relative;
	overflow: hidden;
	padding-right: 50px;
	transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	.text {
		position: relative;
		pointer-events: none;
	}
	&:after {
		content: '\f105';
		font: normal normal normal 14px/1 FontAwesome;
	    font-size: inherit;
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    -webkit-transform: translate(0,0);
	    transform: translate(0,-50%);
		transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	&:before {
		content: ' ';
		transition: left 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		position: absolute;
		display: inherit;
		// padding: 20px 40px;
		height: inherit;
		line-height: inherit;
		justify-content: inherit;
		align-items: center;
		top: 0;
		left: -100%;
		height: 100%;
		width: 100%;
		background: white;
	}
	&:hover {
		&.is-primary {
			color: $primary;
			&:after {
				color: $primary;
			}
		}
		&.is-info {
			color: $dark;
			&:after {
				color: $dark;
			}
		}
		
		&:before {
			left: 0;
		}
		
	}
}

.btn--icon {
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	color: inherit;
	cursor: pointer;
	.icon {
		display: block;
	width: 2rem;
	height: 2rem;
	margin: 0 auto;
	fill: currentColor;
	}
	&:focus {
	outline: none;
}
}