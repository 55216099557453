$input:                     $grey-darker !default;
$input-background:          $white !default;
$input-border:              $grey-lighter !default;

$input-hover:               $grey-darker !default;
$input-hover-border:        $grey-light !default;

$input-focus:               $grey-darker !default;
$input-focus-border:        $link !default;

$input-disabled:            $text-light !default;
$input-disabled-background: $background !default;
$input-disabled-border:     $background !default;

$input-arrow:               $link !default;

$input-icon:                $grey-lighter !default;
$input-icon-active:         $grey !default;

$input-radius:              $radius !default;

@mixin input {
  @include control;
  background-color: $input-background;
  border: 1px solid $input-border;
  color: $input;
  &:hover,
  &.is-hovered {
    border-color: $input-hover-border; }
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    border-color: $input-focus-border; }
  &[disabled],
  &.is-disabled {
    background-color: $input-disabled-background;
    border-color: $input-disabled-border;
    box-shadow: none;
    color: $input-disabled;
    @include placeholder {
      color: rgba($input, 0.3); } } }

.input,
.textarea {
  @include input;
  box-shadow: inset 0 1px 2px rgba($black, 0.1);
  max-width: 100%;
  width: 100%;
  &[type="search"] {
    border-radius: 290486px; }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      border-color: $color; } }
  // Sizes
  &.is-small {
    @include control-small; }
  &.is-medium {
    @include control-medium; }
  &.is-large {
    @include control-large; }
  // Modifiers
  &.is-fullwidth {
    display: block;
    width: 100%; }
  &.is-inline {
    display: inline;
    width: auto; } }

.textarea {
  display: block;
  line-height: 1.25;
  max-height: 600px;
  max-width: 100%;
  min-height: 120px;
  min-width: 100%;
  padding: 10px;
  resize: vertical; }

.checkbox,
.radio {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;
  input {
    cursor: pointer;
    margin-right: 0.5em; }
  &:hover {
    color: $input-hover; }
  &.is-disabled {
    color: $input-disabled;
    pointer-events: none;
    input {
      pointer-events: none; } } }

.radio {
  & + .radio {
    margin-left: 0.5em; } }

.select {
  display: inline-block;
  height: 2.5em;
  position: relative;
  vertical-align: top;
  &:after {
    @include arrow($input-arrow);
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
    z-index: 4; }
  select {
    @include input;
    cursor: pointer;
    display: block;
    font-size: 1em;
    outline: none;
    padding-right: 2.5em;
    &:hover {
      border-color: $input-hover-border; }
    &::ms-expand {
      display: none; } }
  // States
  &:hover {
    &:after {
      border-color: $input-hover; } }
  // Sizes
  &.is-small {
    @include control-small; }
  &.is-medium {
    @include control-medium; }
  &.is-large {
    @include control-large; }
  // Modifiers
  &.is-fullwidth {
    width: 100%;
    select {
      width: 100%; } } }

.label {
  color: $input;
  display: block;
  font-weight: bold;
  &:not(:last-child) {
    margin-bottom: 0.5em; } }

.help {
  display: block;
  font-size: $size-small;
  margin-top: 5px;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      color: $color; } } }

// Containers

.control-label {
  @include mobile {
    margin-bottom: 0.5em; }
  @include tablet {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5em;
    padding-top: 0.5em;
    text-align: right; } }

.control {
  position: relative;
  text-align: left;
  &:not(:last-child) {
    margin-bottom: 0.75rem; }
  // Modifiers
  &.has-addons {
    display: flex;
    justify-content: flex-start;
    .button,
    .input,
    .select {
      border-radius: 0;
      margin-right: -1px;
      width: auto;
      &:hover {
        z-index: 2; }
      &:focus,
      &:active {
        z-index: 3; }
      &:first-child {
        border-radius: $input-radius 0 0 $input-radius;
        select {
          border-radius: $input-radius 0 0 $input-radius; } }
      &:last-child {
        border-radius: 0 $input-radius $input-radius 0;
        select {
          border-radius: 0 $input-radius $input-radius 0; } }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 0; } }
    .select select {
      &:hover {
        z-index: 2; }
      &:focus,
      &:active {
        z-index: 3; } }
    &.has-addons-centered {
      justify-content: center; }
    &.has-addons-right {
      justify-content: flex-end; }
    &.has-addons-fullwidth {
      .button,
      .input,
      .select {
        flex-grow: 1;
        flex-shrink: 0; } } }
  &.has-icon {
    .icon {
      color: $input-icon;
      pointer-events: none;
      position: absolute;
      top: ($size-normal * 2.5) / 2;
      z-index: 4; }
    .input {
      &:focus {
        & + .icon {
          color: $input-icon-active; } }
      &.is-small {
        & + .icon {
          top: ($size-small * 2.5) / 2; } }
      &.is-medium {
        & + .icon {
          top: ($size-medium * 2.5) / 2; } }
      &.is-large {
        & + .icon {
          top: ($size-large * 2.5) / 2; } } }
    &:not(.has-icon-right) {
      .icon {
        left: ($size-normal * 2.5) / 2;
        transform: translateX(-50%) translateY(-50%); }
      .input {
        padding-left: 2.5em;
        &.is-small {
          & + .icon {
            left: ($size-small * 2.5) / 2; } }
        &.is-medium {
          & + .icon {
            left: ($size-medium * 2.5) / 2; } }
        &.is-large {
          & + .icon {
            left: ($size-large * 2.5) / 2; } } } }
    &.has-icon-right {
      .icon {
        right: ($size-normal * 2.5) / 2;
        transform: translateX(50%) translateY(-50%); }
      .input {
        padding-right: 2.5em;
        &.is-small {
          & + .icon {
            right: ($size-small * 2.5) / 2; } }
        &.is-medium {
          & + .icon {
            right: ($size-medium * 2.5) / 2; } }
        &.is-large {
          & + .icon {
            right: ($size-large * 2.5) / 2; } } } } }
  &.is-grouped {
    display: flex;
    justify-content: flex-start;
    & > .control {
      flex-basis: 0;
      flex-shrink: 0;
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 0.75rem; }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 1; } }
    &.is-grouped-centered {
      justify-content: center; }
    &.is-grouped-right {
      justify-content: flex-end; } }
  &.is-horizontal {
    @include tablet {
      display: flex;
      & > .control {
        display: flex;
        flex-basis: 0;
        flex-grow: 5;
        flex-shrink: 1; } } }
  &.is-loading {
    &:after {
      @include loader;
      position: absolute !important;
      right: 0.75em;
      top: 0.75em; } } }
