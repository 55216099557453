.portfolio-list {
	.button {
		margin: 100px 0 0 0;
	}
	.box {
		box-shadow: none;
		border-radius: 0;
		border:none;
		padding: 0;
		position: relative;
	}

	.portfolio--item {
		overflow: hidden;
		position: relative;
		display: block;
		padding: 0;
		height: 50vh;
		&.text--light {
			h1,h2,h3,h4,h5, p {
				color: rgba(255,255,255,0.9);
			}
			.btn--icon {
				.icon {
					fill: white;
				}
			}
		}

		&.text--dark {

		}

		.image-wrap {
			position: absolute;
			transition: all 0.3s 0.15s cubic-bezier(0.645, 0.045, 0.355, 1.000);
			top: 50%;
			left: 50%;
			width: 450px;
			max-width: 85%;
			transform: translateX(-50%) translateY(-50%);
			box-shadow: 0 10px 30px rgba(0,0,0,0.3);
			overflow: hidden;
			img {
				width: 100%;
				display: block;
				&.lazy {
					// filter: blur(1px);
					transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
					&.loaded {
						filter: blur(0);
					}
				}
			}
		}
		.caption {
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
			position: absolute;
			top: 50%;
			width: 75%;
			transform: translateY(-50%);
			opacity: 0;
		}
		&:hover {
			.caption {
				transition: all 0.3s 0.15s cubic-bezier(0.645, 0.045, 0.355, 1.000);
			}
			.image-wrap {
				transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
			}
		}
	}
	.portfolio--lg {
		.portfolio--item {
			@include tablet(){
				height: 500px;
			}
			.caption {
				transform: translateY(-50%);
				left: 55%;
			}
			@include tablet(){
				&:hover {
					.image-wrap {
						left: 5%;
						transform: translateX(0) translateY(-50%);
					}
					.caption {
						left: 50%;
						opacity: 1;
					}
				}
			}
		}		
	}

	.portfolio--md {
		.portfolio--item {
			@include tablet(){
				height: 700px;
			}
		}
		.caption {
			top: 80%;
			left: 50%;
			transform: translateX(-50%);
		}
		@include tablet(){
			&:hover {
				.image-wrap {
					transform: translateX(-50%) translateY(0);
					top: 10%;
				}
				.caption {
					top: 75%;
					opacity: 1;
				}
			}
		}
	}
	.portfolio--sm {
		.portfolio--item {
			@include tablet(){
				height: 480px;
			}
		}
		.caption {
			top: 80%;
			left: 50%;
			transform: translateX(-50%);
			font-size: 16px;
			h3 {
				font-size: 22px;
			}

		}
		@include tablet(){
			&:hover {
				.image-wrap {
					transform: translateX(-50%) translateY(0);
					top: 5%;
				}
				.caption {
					top: 70%;
					opacity: 1;
				}
			}
		}
	}
	// .portfolio--item {
	// 	overflow: hidden;
	// 	position: relative;
	// 	display: block;
	// 	padding: 0;
	// 	height: 100%;

	// 	.image-wrap {
	// 		background-repeat: no-repeat;
	// 		background-size: cover;
	// 		background-position: center center;
	// 		height: 100%;
	// 		position: relative;
	// 		overflow: hidden;
	// 		img {
	// 		  	opacity: 0;
	// 		}
	// 	}
		
	// 	.caption {
	// 		position: absolute;
	// 		top: 50%;
	// 		left: 50%;
	// 		transform: translateX(-50%) translateY(-50%);
	// 		// width: 100%;
	// 		// height: 100%;
	// 		padding: 100px 50px;
	// 		opacity: 0;
	// 		text-align: center;
	// 		transition: all 0.3s  linear;
	// 		z-index: 100;
	// 		font-weight: 400;
	// 		h1,h2,h3,h4,h5, p {
	// 			color: white;
	// 		}
	// 		h3 {
	// 			font-size: 42px;
	// 			font-weight: 900;
	// 			text-transform: uppercase;
	// 		}

	// 		.portfolio--title, .portfolio--description, .btn--icon {
	// 			transition: all 0.3s linear;
	// 			transform: translateX(-10%);
	// 			opacity: 0;
	// 		}
	// 		.portfolio--title {transition-delay:0.3s;}
	// 		.portfolio--description {transition-delay:0.4s;}
	// 		.btn--icon {transition-delay:0.45s;}

	// 		.icon {
	// 			fill: white;
	// 			height: 30px;
	// 			width: auto;
	// 		}
	// 	}

	// 	&:before, &:after {
	// 		content: ' ';
	// 		position: absolute;
	// 		top: 0;
	// 		left: -100%;
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// 	&:after {
	// 		background: rgba(white,1);
	// 		transition: all 0.6s 0.3s linear;
	// 	}
	// 	&:before {
	// 		z-index: 1;
	// 		background: rgba($primary, 0.95);
	// 		transition: all 0.3s 0.3s linear;
	// 	}
		
	// 	&:hover {
	// 		&:before {
	// 			left: 0%;
	// 			transition: all 0.3s 0.3s linear;
	// 		}
	// 		&:after {
	// 			left: 100%;
	// 			transition: all 0.6s linear;
	// 		}
	// 		.caption {
	// 			transition: all 0.3s 0.3s linear;
	// 			opacity: 1;
	// 		}
	// 		.portfolio--title, .portfolio--description, .btn--icon {
	// 			transform: translateX(0);
	// 			opacity: 1;
	// 		}

	// 	}
	// }
}


.page--header {
	background-repeat: no-repeat;
		background-position: center center;
	&.bg--auto {
		background-size: auto 100%;
		
	}
	&.bg--cover {
		background-size: cover;
	}
	&.text--dark {
		h1,h2,h3,h4,h5 {
			color: $text-color;
		}
	}
	h2 {

	}
}

.portfolio--header {
	h2 {
		font-size: 18px;
	}
	@include mobile() {
		h1 {
			font-size: 7vw;
		}
		h2 {
			font-size: 3vw;
		}
		.caption {
			top: 40%;
			width: 80%;
		}
	}
}

.portfolio--link {
	margin-top: 50px;
}



.rmbp {
	margin-top: -200px;
	.image {
		position: relative;
		.screen {
			position: absolute;
			width: 75%;
			left: 50%;
			top: 7%;
			height: 83%;
			transform: translateX(-50%);
			overflow-x: hidden;
			overflow-y: visible;
		}
	}
}