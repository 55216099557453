 @mixin breakpoint($size) {
 	@media (min-width: $size) { @content; }
 }

* {
	box-sizing: border-box;
}
body {
	margin: 0;
}

.page--header {
	background: $primary;
}
.content {
	padding: 150px 0;
	margin-bottom: 0 !important;
	@include mobile(){
		padding: 100px 30px;
	}
	&.content--gray {
		background-color: $gray;
	}
	.content--title {
		padding-bottom: 100px;
	}
}

.hidden {
	display: none;
}

.text-center {
	text-align: center;
}

.page--header {
	height: 70vh;
	position: relative;
	.caption {
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		h1,h2,h3,h4,h5 {
			color: rgba(255,255,255,0.8);
			font-weight: bold;
		}
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.9;
		z-index: 0
	}
}




.loading-screen{
	background: white;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 1;
	visibility: visible;
	pointer-events: none;
	transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1.000);
	left: 100%;
	// &:before, &:after {
	// 	content: ' ';
	// 	display: block;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// }
	// &:before {
	// 	left: -100%;
	// 	background: white;
	// }
	// &:after {
	// 	left: -100%;
	// 	background: $gray;
	// 	transition-delay: 0.4s;
	// }
	.bar{
		display: none;
		transition : width 0.2s ease;
		position: absolute;
		top: 0px;
		height: 100%;
		background: $gray;
		width: 0;
	}
	&.active{
		left: 0;
	// pointer-events: normal;
		// &:before, &:after {
		// 	left: 0;
		// }
	}
	.loader--container{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 300px;
		height: 100px;
		.logo{
			opacity: 1;
		}
		.loader-content{
			transform: translateY(-50%) translateX(-50%);
			.loader-inner{
				margin-top: 20px;
				text-align: center;
				div{
					width: 10px;
					height: 10px;
					margin: 10px;
				}
			}
		}
	}
}



.footer {
	background: white;
	padding: 100px 40px;
	font-size: 16px;
	text-align: left;
	a {
		color: $text-color;
	}
	@include tablet(){
		text-align: center;
		padding: 100px 0;
	}
	.footer--logo {
		width: 100px;
		margin-bottom: 30px;
		border-radius: 20px;
		padding: 20px;
		display: inline-block;
		background: #E0E0EB;
		svg {
			display: block;
			width: 100%;
			height: 50px;
		}
	}
	ul.footer--nav {
		list-style: none;
		padding: 0;
		margin: 20px 0 20px 0;
		
		li {
			display: block;
			@include tablet(){
				display: inline-block;
			}
			a {
				padding: 10px 00px 10px 0px;
				position: relative;
				display: block;
				font-size: 16px;
				color: $text-color;
				@include tablet(){
					padding: 10px 20px 10px 20px;
					&:after {
						content: '/';
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);

					}
				}
			}
			@include tablet(){
				&:first-child {
					a{
						padding-left: 0;
					}
				}
				&:last-child {
					a {
						&:after{
							display: none;
						}
					}
				}
			}
		}
	}
}