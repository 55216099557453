.blog--list {

	.blog--item {
		overflow: hidden;
		position: relative;
		display: block;
		padding: 0;
		color: $text-color;
		.blog--image {
			background-size: cover;
			background-repeat: no-repeat;
			height: 25vh;
		}
		.caption {
			padding: 30px;
		}
	}
	.tile.is-child {
		padding: 0; 
	}
}