@keyframes pulse{
  0%   { opacity: 1; width: 0; height: 0; }
  100% { opacity: 0; width: 75px; height: 75px; }
}

.header {
	// padding: 30px 20px;
	height: 120px;
	position: fixed;
	top: 0;
	left:0;
	width: 100%;
	z-index: 50;
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	// &.headroom--not-top {
	// 	background: rgba(255,255,255,0.95);
	// 	// height: 70px;
	// }
	&.light {
		svg {
			#logo--group {
				fill : url(#gradient-light);
			}
		}
		.icon--menu {
			fill: white;
		}
	}		
	.top-bar {
		.logo--container {
			position: absolute;
			top: 50px;
			left: 50px;
			color: #939391;
			pointer-events: visible;
			@include mobile(){
				top: 25px;
				left: 25px;
			}
			
		}
		.nav--container {
			position: absolute;
			top: 50px;
			right: 50px;
			@include mobile(){
				top: 25px;
				right: 25px;
			}	

		}
		&:after {
			content: ' ';
			clear: both;
			display: block;
		}
	}	
	.btn--menu {
		color: #939391;
		pointer-events: visible;
	}
	.logo--container {
			width: 40px;
			h3 {
				margin: 0;
				line-height: 1em;
			}
			img, svg {
				max-width: 100%;
				height: 28px;
				#logo--group {
					transition: all 0.3s linear;
				}

			}
			
			a {
				display: inline-block;
				position: relative;
				&:after {
					content: ' ';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 0;
					height: 0;
					transition: all 0.3s linear;
					border-radius: 100%;
					background: rgba($primary, 0.5);

				}
				&:hover {
					&:after {
						animation: pulse 0.3s 1;
					}
				}
			}
	}
	.nav {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			a {
				// color: white;
				// display: block;
				padding: 0px 30px;
				color: $text-color;
				font-weight: 700;
				text-transform: uppercase;
				&:hover {
					color: $primary;
				}
			}
		}
	}
}

.ccm-toolbar-visible {
	.header {
		top: 50px;
	}
}




.menu {
	opacity: 0;
	&.block-revealer {
		opacity: 1;
	}
	.block-revealer__content {
		visibility: hidden;
	}
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 420px;
	z-index: 100;
	pointer-events: none;

	.menu--inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 30vh 3em;
		margin: 0;
		background: #4F5CCB;
		overflow: hidden;
		.menu--nav {
			margin: 0;
			padding: 0;
		list-style-type: none;

		}
		.menu--item {
			padding: 20px;
			transform: translate3d(-13em,0,0);
			transition: transform 0s 0.4s;
		}
		.menu--link {
			font-size: 52px;
			text-decoration: none;
			color: rgba(255,255,255,0.8);
			font-weight: bold;
			transition: color 0.2s linear;
			&:hover, &:focus {
				outline: none;
				color: rgba(255,255,255,1);
				// text-decoration: line-through;
			}
		}


	}
	.btn--close {
			opacity: 0;
			position: absolute;
			font-size: 0.75em;
			z-index: 100;
			top: 50px;
			right: 50px;
			@include mobile(){
				top: 25px;
				right: 25px;
			}	
		}

	&.menu--open {
		pointer-events: visible;
		.menu--item, .btn--close {
			opacity: 1;
			transform: translate3d(0,0,0);
			transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
		.menu--item{
			&:nth-child(2) {
				transition-delay: 0.165s;
			}
			&:nth-child(3) {
				transition-delay: 0.195s;
			}
			&:nth-child(4) {
				transition-delay: 0.225s;
			}
			&:nth-child(5) {
				transition-delay: 0.255s;
			}
			&:nth-child(6) {
				transition-delay: 0.305s;
			}
		}
	}
}







.menu .menu--link:hover,
.menu .menu--link:focus {
	outline: none;
	color: #000;
	text-decoration: line-through;
}