$nav-height: 3.5rem !default;

// Components

.nav-toggle {
  @include hamburger($nav-height);
  // Responsiveness
  @include tablet {
    display: none; } }

.nav-item {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: $size-normal;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  a {
    flex-grow: 1;
    flex-shrink: 0; }
  img {
    max-height: 1.75rem; }
  .button + .button {
    margin-left: 0.75rem; }
  .tag {
    &:first-child:not(:last-child) {
      margin-right: 0.5rem; }
    &:last-child:not(:first-child) {
      margin-left: 0.5rem; } }
  // Responsiveness
  @include mobile {
    justify-content: flex-start; } }

.nav-item a,
a.nav-item {
  color: $text-light;
  &:hover {
    color: $link-hover; }
  // Modifiers
  &.is-active {
    color: $link-active; }
  &.is-tab {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    padding-bottom: calc(0.5rem - 1px);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc(0.5rem - 1px);
    &:hover {
      border-bottom-color: $primary;
      border-top-color: transparent; }
    &.is-active {
      border-bottom: 3px solid $primary;
      color: $primary;
      padding-bottom: calc(0.5rem - 3px); } }
  // Responsiveness
  @include desktop {
    &.is-brand {
      padding-left: 0; } } }

// Containers

.nav-menu {
  // Responsiveness
  @include mobile {
    background-color: $white;
    box-shadow: 0 4px 7px rgba($black, 0.1);
    left: 0;
    display: none;
    right: 0;
    top: 100%;
    position: absolute;
    .nav-item {
      border-top: 1px solid rgba($border, 0.5);
      padding: 0.75rem; }
    &.is-active {
      display: block; } }
  @include tablet-only {
    padding-right: 1.5rem; } }


.nav-left,
.nav-right {
  align-items: stretch;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0; }

.nav-left {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap; }

.nav-center {
  align-items: stretch;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-left: auto;
  margin-right: auto; }

.nav-right {
  justify-content: flex-end;
  // Responsiveness
  @include tablet {
    display: flex; } }

// Main container

.nav {
  align-items: stretch;
  background-color: $white;
  display: flex;
  min-height: $nav-height;
  position: relative;
  text-align: center;
  z-index: 2;
  & > .container {
    align-items: stretch;
    display: flex;
    min-height: $nav-height;
    width: 100%; }
  // Modifiers
  &.has-shadow {
    box-shadow: 0 2px 3px rgba($black, 0.1); } }
